import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styles } from './style';
import { Icon } from '@iconify/react';
import theme from '../../Theme';
import ShareButtonComponent from './Share';
import axios from 'axios';
import Swal from 'sweetalert2';
import { API_BASE_URL, IMAGE_URL, BASE_URL } from '../../url';

function QRCodeItem() {
    const [QrData, setQrData] = useState(null);
    const [tracks, setTracks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [qrCodeData, setQrCodeData] = useState([]);
    const [artistData, setArtistData] = useState(null);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    const getArtistData = async () => {
        try {
            const responseData = await axios.post(API_BASE_URL + 'artistLogin', {
                email: currentUser.email,
                typeOfLogin: currentUser.typeOfLogin,
            });
            if (!responseData.data.status) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                });
                setIsLoading(false);
            } else {
                const data = responseData.data.data;
                setArtistData(data.artist);
                setTracks(data.tracks);
                setIsLoading(false);
            }
        } catch (error) {
            setError(error);
            setIsLoading(false);
        }
    };

    const getQrCode = async (artistId) => {
        try {
            const responseData = await axios.post(API_BASE_URL + 'get_tracks_by_artist', {
                id: artistId,
            });
          console.log("===responseData==",responseData)
            if (!responseData.data.status) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                });
            } else {
                const data = responseData.data.data;
                console.log("===data==",data)
                setQrCodeData(data); 
            }
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getArtistData();
    }, []);

    useEffect(() => {
        if (artistData?.id) {
            getQrCode(artistData.id);
        }
    }, [artistData]);

    if (isLoading) {
        return <Typography>Loading...</Typography>;
    }

    if (error) {
        return <Typography>Error: {error.message}</Typography>;
    }

    return (
<Box sx={styles.DropBox}>
    {qrCodeData.length > 0 ? (
        qrCodeData.map((item, index) => (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row', // Items arranged horizontally within each row
                    alignItems: 'center',
                    width: '100%',
                    marginBottom: '20px', // Add space between rows
                    padding: '20px',
                    border: '1px solid #e0e0e0', // Optional: Add border for better separation
                    borderRadius: '8px', // Optional: Add border-radius for rounded corners
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', // Optional: Add box-shadow for better visual appeal
                }}
                key={index}
            >
                {/* QR Code Image */}
                <Box sx={{ flex: '1 1 30%', marginRight: '20px' }}>
                    <Box
                        component={'img'}
                        src={`${IMAGE_URL}${item.qr_image}`}
                        alt="QR Code"
                        sx={{
                            width: '100px',
                            height: '100px',
                            objectFit: 'cover',
                            borderRadius: '8px', // Optional: Add rounded corners to the image
                        }}
                    />
                </Box>

                {/* Content Section */}
                <Box sx={{ flex: '1 1 60%' }}>
                    <Typography variant="h6" sx={styles.DropBoxTitle}>
                        {item.album_name}
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            lineHeight: '1',
                            color: theme.palette.primary.textColor,
                            marginBottom: '10px', // Add space between the rows of content
                        }}
                    >
                        <Box sx={{ fontSize: '22px' }}>
                            <Icon icon="iconamoon:link-light" />
                        </Box>
                        <Typography component={'p'} sx={styles.DropBoxSubTitle}>
                            {item.album_type == 0 ? 'Track' : 'Album'}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            lineHeight: '1',
                            color: theme.palette.primary.textColor,
                        }}
                    >
                        <Box sx={{ fontSize: '22px' }}>
                            <Icon icon="ph:arrow-bend-up-right-fill" />
                        </Box>
                        <Typography
                            component={'a'}
                            href={`${BASE_URL}${item.slug}`}
                            sx={styles.DropBoxSubTitle}
                        >
                            {`${BASE_URL}${item.slug}`}
                        </Typography>
                    </Box>
                </Box>

                {/* Share Button Section */}
                <Box sx={{ flex: '1 1 10%', textAlign: 'right' }}>
                    <ShareButtonComponent />
                </Box>
            </Box>
        ))
    ) : (
        <Typography style={{ textAlign: 'center' }}>
            No tracks available
        </Typography>
    )}
</Box>
    );
}

export default QRCodeItem;
