// import styled from '@emotion/styled';
// import { Avatar, Box, List, Typography, } from '@mui/material'
import { Avatar, Box, Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { selectCurrentUser } from "../../redux/reducers/userSlice";
import { useAppSelector } from "../../redux/hooks";
// import React, { useEffect, useState } from 'react'
// import theme from '../../Theme';
// import axios from 'axios';
// import SocialLink from '../../components/SocialLink';
// import TrackLink from '../../components/TrackLink';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Carousel from "../../components/Carousel";
import theme from "../../Theme";
import { faqContent } from "../../utils/common";
import { styles } from "./style";
const Home = () => {
  const [activeTab, setActiveTab] = React.useState(0);

  const currentUser = useAppSelector(selectCurrentUser);

  useEffect(() => {
    // console.log("currentUser", currentUser);
  }, [currentUser]);

  return (
    <>
      <Container maxWidth="xl" sx={styles.homeContainer}>
        <Header />
        <Typography
          sx={{
            color: theme.palette.primary.logoColor,
            textAlign: "left",
            fontSize: {
              xs: "28px",
              sm: "28px",
              md: "40px",
              lg: "40px",
            },
            fontWeight: "700",
            lineHeight: {
              xs: "28.9px",
              sm: "28.9px",
              md: "57.8px",
              lg: "57.8px",
            },
            marginTop: {
              xs: "20px",
              sm: "20px",
              md: "151px",
              lg: "151px",
            },
            
          }}
          className="revealator-slidedown revealator-duration15 revealator-delay5"
        >
          A Message from Our Founder
        </Typography>

        <Box
  sx={{
                      backgroundColor: "rgba(255, 255, 255, 0.4)", // 40% transparent white background
                      borderRadius: "64px",
            marginTop: "20px",
            marginBottom: "244px",
                        
  }}
>
  <Box
    sx={{
      display: "flex",
      flexDirection: {
        xs: "column", // Stack vertically on small screens
        sm: "column",
        md: "row", // Side by side on medium and larger screens
        lg: "row",
      },
      justifyContent: "space-between",
      alignItems: "center",
      padding: {
        xs: "20px",
        sm: "20px",
        md: "40px",
        lg: "40px",
              },
    }}
  >
    {/* First Box */}
    <Box
      sx={{
        width: {
          xs: "100%", // Full width on small screens
          sm: "100%",
          md: "30%", // 50% width on medium and larger screens
          lg: "30%",
        },
        display: "flex",
        flexDirection: "column",
                              justifyContent: "center",
                              borderRight: {
                                    xs: "0",
                                    sm: "0",
                                    md: "4px solid rgba(117, 132, 148, 0.15)",
                                    lg: "4px solid rgba(117, 132, 148, 0.15)",
                              },
                                paddingRight: {
                                        xs: "0",
                                        sm: "0",
                                        md: "41px",
                                        lg: "41px",
                },
                                
                                className: "revealator-slideleft revealator-duration15 revealator-delay5"
        
      }}
                      >
        
                          <Box sx={{
                              display: "flex", flexDirection: "column", alignItems: "center",
                          }}>
                              <Box sx={{
                                
                                  width: {
                                      xs: "50%", // Full width on small screens
                                      sm: "50%",
                                      md: "100%", // 50% width on medium and larger screens
                                      lg: "100%",
                                        
                                  },
                                  
                                  height: {
                                      xs: "auto",
                                      sm: "auto",
                                      md: "auto",
                                      lg: "auto",
                                    },
                                objectFit: 'contain',
                                }}
                            component={"img"} src={"/assets/images/about_us.png"} />    
                              <Typography sx={{
                                  color: theme.palette.primary.logoColor,
                                  textAlign: "center",
                                    fontSize: {
                                        xs: "32px",
                                        sm: "32px",
                                        md: "32px",
                                        lg: "32px",
                                  },
                                  fontWeight: "700",
                                    lineHeight: {
                                        xs: "46.24px",
                                        sm: "46.24px",
                                        md: "46.24px",
                                        lg: "46.24px",
                                  },
                                    marginTop: {
                                            xs: "20px",
                                            sm: "20px",
                                            md: "25px",
                                            lg: "25px",
                                  },
                              }}>
                                  Theo Bailey
                              </Typography>
                              <Typography
                                  sx={{
                                      color: "rgba(117, 132, 148, 1)",
                                      textAlign: "center",
                                      fontSize: {
                                            xs: "22px",
                                            sm: "22px",
                                            md: "22px",
                                            lg: "22px",
                                        }
                                  }}
                              >
                              Founder, SOUND3
                              </Typography>
                        </Box>
    </Box>
                    
    {/* Second Box */}
    <Box
      sx={{
        width: {
          xs: "100%", // Full width on small screens
          sm: "100%",
          md: "70%", // 50% width on medium and larger screens
          lg: "70%",
        },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingLeft: {
          xs: "0", // No padding on small screens
          sm: "0",
          md: "41px", // Padding between the two boxes on larger screens
          lg: "41px",
        },
      }}
    >
                          <Typography
                              sx={{
                                    color: "rgba(117, 132, 148, 1)",
                                    textAlign: "justify",
                                    fontSize: {
                                        xs: "20px",
                                        sm: "20px",
                                        md: "20px",
                                        lg: "20px",
                                  },
                                  lineHeight: {
                                        xs: "34px",
                                        sm: "34px",
                                        md: "34px",
                                        lg: "34px",
                                  },
                                  marginTop: "15px"
                              }}
                          >
    Since 2022, I've made several angel investments into web3 music platforms, giving me a unique perspective on the challenges artists face when navigating the web3 landscape. As an entrepreneur, I couldn't resist the opportunity to build on these insights, which led to the launch of SOUND3            
                          </Typography>
                          
                          <Typography
                              sx={{
                                    color: "rgba(117, 132, 148, 1)",
                                    textAlign: "justify",
                                    fontSize: {
                                        xs: "20px",
                                        sm: "20px",
                                        md: "20px",
                                        lg: "20px",
                                  },
                                  lineHeight: {
                                        xs: "34px",
                                        sm: "34px",
                                        md: "34px",
                                        lg: "34px",
                                  },
                                  marginTop: "15px"
                              }}
                          >
                              Artists often encounter complex blockchain jargon, nuanced cultural differences, and a steep learning curve, resulting in decision fatigue due to the multitude of options available. Recognizing these hurdles, we at SOUND3 are committed to removing friction for artists. We do this by eliminating chain tribalism and maximalist behavior, simplifying wallet creation, and reducing the cognitive load required to learn new systems.
                          </Typography>

                          <Typography
                              sx={{
                                    color: "rgba(117, 132, 148, 1)",
                                    textAlign: "justify",
                                    fontSize: {
                                        xs: "20px",
                                        sm: "20px",
                                        md: "20px",
                                        lg: "20px",
                                  },
                                  lineHeight: {
                                        xs: "34px",
                                        sm: "34px",
                                        md: "34px",
                                        lg: "34px",
                                  },
                                  marginTop: "15px"
                              }}
                          >
                              Our end goal is to help artists generate more revenue from their music. This specialization is what sets us apart, and we expect to become major players in music distribution within the next three to five years.
                          </Typography>


                          <Typography
                              sx={{
                                    color: "rgba(117, 132, 148, 1)",
                                    textAlign: "justify",
                                    fontSize: {
                                        xs: "20px",
                                        sm: "20px",
                                        md: "20px",
                                        lg: "20px",
                                  },
                                  lineHeight: {
                                        xs: "34px",
                                        sm: "34px",
                                        md: "34px",
                                        lg: "34px",
                                  },
                                  marginTop: "15px"
                              }}
                          >
                              Just like DistroKid, TuneCore, and UnitedMasters do for the web2 world, we do the same in web3. We distribute to all major web3 platforms across all major chains so you don't have to.
                          </Typography>
    </Box>
  </Box>
        </Box>


      </Container>
      <Footer />
    </>
  );
};

export default Home;
