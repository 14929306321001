import { TableHead } from "@mui/material"
import theme from "../../Theme"

export const styles = {
    blurViewDataButton: {
        display: {
            xs: 'none',
            sm: 'none',
            md: 'none',
            lg: 'block',
        },
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)', // Center the button inside the li
        backgroundColor: '#3f51b5',
        color: '#fff',
        padding: '10px 20px',
        borderRadius: '8px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Optional shadow for a better look
        },
    DropBox: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: {
            xs: "column",
            sm: "row",
            md: "row",
            lg: "row",
        },
        gap: "20px",
        padding: {
            xs: "20px",
            sm: "20px",
            md: "20px",
            lg: "20px",
            xl: '20px 25px',
        },
        borderRadius: "20px",
        border: `1px solid ${theme.palette.primary.borderColor}`,
        backgroundColor: theme.palette.primary.Lightbg2,
        margin: "20px 0px",
    },
    DropLeftArea: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "20px",
    },
    DropImg: {
        width: {
            xs: '100px',
            sm: '100px',
            md: '120px',
            lg: '120px',
            xl: '130px',
        },
        height: {
            xs: '100px',
            sm: '100px',
            md: '110px',
            lg: '110px',
            xl: '120px',
        },
        borderRadius: "20px",
        overflow: "hidden",
        position: "relative",
    },
    DropContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "space-between",
        flexDirection: "column",
        gap: "4px",
    },
    DropBoxTitle: {
        fontSize: {
            xs: "16px",
            sm: "16px",
            md: "18px",
            lg: "18px",
            lx: "20px",
        },
        fontWeight: "600",
        lineHeight: "24px",
        color: theme.palette.primary.logoColor,
    },
    DropBoxSubTitle: {
        fontSize: {
            xs: "14px",
            sm: "14px",
            md: "16px",
            lg: "16px",
        },
        fontWeight: "600",
        lineHeight: "24px",
        color: theme.palette.primary.textColor,
    },
    DropSubTIme: {
        fontSize: {
            xs: "12px",
            sm: "12px",
            md: "14px",
            lg: "14px",
        },
        fontWeight: "400",
        lineHeight: "24px",
        color: theme.palette.primary.textColor,
    },
    DropBtn: {
        width: {
            xs: '100%',
            sm: '100%',
            md: 'auto',
            lg: 'auto',
        },
        backgroundColor: 'transparent',
        boxShadow: 'none',
        padding: {
            xs: '8px 10px',
            sm: '8px 10px',
            md: '10px 20px',
            lg: '10px 20px',
        },
        borderRadius: '8px',
        display: "flex",
        justifyContent: "center",
        border: `1px solid ${theme.palette.primary.logoColor}`,
        gap: '10px',
        alignItems: "center",
        "&:hover": {
            backgroundColor: 'transparent',

        },
    },
    DropBoxBtnText: {
        color: theme.palette.primary.logoColor,
        fontSize: {
            xs: '12px',
            sm: '12px',
            md: '14px',
            lg: '14px',
        },
        fontWeight: '600',
    },
    DropBtnIcon: {
        fontSize: {
            xs: '16px',
            sm: '16px',
            md: '20px',
            lg: '20px',
        },
        color: theme.palette.primary.white,
        height: {
            xs: '20px',
            sm: '20px',
            md: '30px',
            lg: '30px',
        },
        width: {
            xs: '20px',
            sm: '20px',
            md: '30px',
            lg: '30px',
        },
        borderRadius: {
            xs: '5px',
            sm: '5px',
            md: '5px',
            lg: '8px',
        },
        backgroundColor: theme.palette.primary.logoColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    DropBoxInfoItem: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "10px",
    },
    DropBoxInfoText: {
        fontSize: {
            xs: "12px",
            sm: "12px",
            md: "14px",
            lg: "14px",
        },
        fontWeight: "400",
        lineHeight: "24px",
        color: theme.palette.primary.para,
    },
    DropBoxInfoValue: {
        fontSize: {
            xs: "12px",
            sm: "12px",
            md: "14px",
            lg: "14px",
        },
        fontWeight: "600",
        lineHeight: "24px",
        color: theme.palette.primary.logoColor,
    },
    ViewDataBtn: {
        display: {
            xs: "block",
            sm: "block",
            md: "block",
            lg: "none",
        },
        backgroundColor: 'transparent',
        color: theme.palette.primary.logoColor,
        border: `1px solid ${theme.palette.primary.logoColor}`,
        minWidth: 'fit-content',
        width: 'fit-content',
        padding: {
            xs: '8px',
            sm: '8px',
            md: '8px 12px',
            lg: '8px 12px',
        },
        borderRadius: '8px',
        textTransform: 'capitalize',
        boxShadow: 'none',
        fontSize: {
            xs: '10px',
            sm: '10px',
            md: '12px',
            lg: '12px',
        },
        fontWeight: '600',
        lineHeight: '1',
        "&:hover": {
            backgroundColor: 'transparent',
        },
    },
    TableHead: {
        backgroundColor: '#F5F8FF', // Keep the background color of the header
        borderBottom: '2px solid #E0E0E0', // Ensure the header has a solid bottom border
        alignItems: 'center', // Center the content vertically
    },
    TableBody: {
        backgroundColor: 'transparent', // Remove any shadow effect on the body
    },
    TableContainer: {
        width: '100%',
        overflowX: 'auto', // Allow horizontal scroll on small screens
    },
    TableCell: {
    border: 'none', // Remove all borders from the table cells
    padding: '16px', // Adjust padding to ensure consistent spacing
    },
      ModalHeaderText: {
        marginBottom: '20px',
        fontWeight: 'bold',
        textAlign: 'center',
    },
      TableRow: {
        borderBottom: '1px solid #E0E0E0', // Only bottom border
        '&:last-child': {
            borderBottom: 'none', // Remove bottom border from the last row
        },
        },
      '@media (max-width: 600px)': {
        TableRow: {
          display: 'flex',
          flexDirection: 'column', // Stack rows vertically on small screens
        },
        TableCell: {
          textAlign: 'center', // Center text on mobile
        },
      },
    MintDataBtn: {
        display: {
            xs: "block",
            sm: "block",
            md: "block",
            lg: "block",
        },
        backgroundColor: 'transparent',
        color: theme.palette.primary.logoColor,
        border: `1px solid ${theme.palette.primary.logoColor}`,
        minWidth: 'fit-content',
        width: 'fit-content',
        padding: {
            xs: '8px',
            sm: '8px',
            md: '8px 12px',
            lg: '8px 12px',
        },
        borderRadius: '8px',
        textTransform: 'capitalize',
        boxShadow: 'none',
        fontSize: {
            xs: '10px',
            sm: '10px',
            md: '12px',
            lg: '12px',
        },
        fontWeight: '600',
        lineHeight: '1',
        "&:hover": {
            backgroundColor: 'transparent',
        },
    },
    ModalMintsUl: {
        display: 'grid',
        gridTemplateColumns: {
          xs: 'repeat(2, 1fr)', // 2 columns for mobile view
          md: 'repeat(3, 1fr)', // 3 columns for medium and large screens
        },
        gap: '20px', // Gap between grid items
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px', // Add some padding
      },
      ModalMintsLi: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '15px',
        borderRadius: '8px',
        backgroundColor: '#ffffff',
        // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Slight shadow for better visibility
      },
      ModalMintIcon: {
        width: '60px',
        height: '60px',
        objectFit: 'contain',
        marginBottom: '10px', // Space between icon and text
      },
      ModalMintInfoText: {
        fontSize: '14px',
        color: '#6E6E6E',
        marginBottom: '5px',
    },
    ModalHeaderText: {
          color: "#003852",
      },
      ModalMintInfoValue: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#000000',
    },
        modalCloseButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
    color: '#000',
    zIndex: 10,
  },
MintsUl: {
    display: {
        xs: "none",
        md: "none",
        lg: "flex",
        xl: "flex",

    }, // Show on medium and larger screens, hide on small screens
  justifyContent: "center",
  alignItems: "center",
    gap: "0px",
    filter: "blur(5px)", // Apply the blur effect
},

    MintsLi: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
        padding: "0px 30px",
        borderLeft: `1px solid ${theme.palette.primary.borderColor}`,
    },
    MintIcon: {
        width: '50px',
        height: '50px',
        objectFit: 'cover',
    },
    MintInfo: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "0px",
    },
    MintInfoItem: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "10px",
    },
    MintInfoText: {
        fontSize: {
            xs: "14px",
            sm: "14px",
            md: "16px",
            lg: "16px",
        },
        fontWeight: "500",
        lineHeight: "24px",
        color: theme.palette.primary.para,
    },
    MintInfoValue: {
        fontSize: {
            xs: "14px",
            sm: "14px",
            md: "16px",
            lg: "16px",
        },
        fontWeight: "600",
        lineHeight: "24px",
        color: theme.palette.primary.logoColor,
    },
    ButtonGroups: {
        display: {
            xs: "flex",
            sm: "grid",
            md: "grid",
            lg: "grid",
            xl: "none",

        },
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "10px",
    },
    DataBtn: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.logoColor,
        border: `1px solid ${theme.palette.primary.logoColor}`,
        padding: "10px 20px",
        width: {
            xs: "50%",
            sm: "auto",
            md: "auto",
            lg: "auto",
            xl: "auto",
        },
        borderRadius: "8px",
        textTransform: "capitalize",
        boxShadow: 'none',
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "1",
        "&:hover": {
            backgroundColor: 'transparent',
        },
    },
    MintsBtn: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.logoColor,
        border: `1px solid ${theme.palette.primary.logoColor}`,
        padding: "10px 20px",
        width: {
            xs: "50%",
            sm: "auto",
            md: "auto",
            lg: "auto",
            xl: "auto",
        },
        borderRadius: "8px",
        textTransform: "capitalize",
        boxShadow: 'none',
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "1",
        "&:hover": {
            backgroundColor: 'transparent',
        },
    },
    
}