import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import theme from './Theme';
import { HelmetProvider } from 'react-helmet-async';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
// import {  getClientSecret } from './utils/common';

const root = ReactDOM.createRoot(document.getElementById('root'));

// const stripePromise = loadStripe('pk_test_CIptLw3IEqegLCqMu1FGcMHC');

// const options = {
//   // passing the client secret obtained from the server
//   clientSecret: "pi_3PERmkHnl45tktFv1Kxb2S7C_secret_thpf0e4HIbrwL41j4Sh8rz91t",
// };

// const options = {
//   // passing the client secret obtained from the server
//   clientSecret: await getClientSecret(),
// };
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        {/* <Elements stripe={stripePromise} options={options}> */}
          <App />
        {/* </Elements> */}
      </ThemeProvider>
    </HelmetProvider>
  </React.StrictMode>
);

