import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { styles } from "./style";
import axios from "axios";
import Swal from "sweetalert2";
import { API_BASE_URL, IMAGE_URL } from "../../url";
import Modal from "react-modal";
import { convertDateFormat } from "../../utils/common";

const mintTypes = [
  { key: "demu_mint", label: "Demu Mint", amountKey: "demu_amount" },
  { key: "sound_xyz_mint", label: "Sound XYZ Mint", amountKey: "sound_xyz_amount" },
  { key: "nftempo_mint", label: "NFTempo Mint", amountKey: "nftempo_amount" },
  { key: "newm_mint", label: "NewM Mint", amountKey: "newm_amount" },
  { key: "jukebox_mint", label: "Jukebox Mint", amountKey: "jukebox_amount" },
  { key: "audius_mint", label: "Audius Mint", amountKey: "audius_amount" },
  { key: "royalio_mint", label: "Royalio Mint", amountKey: "royalio_amount" },
];

function AnalyticsItem() {
  const [artistData, setArtistData] = useState(null);
  const [tracks, setTracks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [trackDetail, setTrackDetail] = useState(null);
  const [artisanDetail, setArtisanDetail] = useState(null);
  const [isButtonVisible, setIsButtonVisible] = useState(true); // State to manage button visibility
  const [mintDataModalOpen, setMintDataModalOpen] = useState(false); // For mint data modal
  const [mintData, setMintData] = useState([]); // For storing mint data
  
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));

  const getArtistData = async () => {
    try {
      const responseData = await axios.post(API_BASE_URL + "artistLogin", {
        email: currentUser.email,
        typeOfLogin: currentUser.typeOfLogin,
      });

      if (!responseData.data.status) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        setIsLoading(false);
      } else {
        const data = responseData.data.data;
        setArtistData(data.artist);
        setTracks(data.tracks);
        setIsLoading(false);
      }
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  const getArtisianDetail = async () => {
    if (artistData) {
      try {
        const responseData = await axios.post(API_BASE_URL + "get_mints_by_artist", {
          id: artistData.id,
        });
        if (!responseData.data.status) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        } else {
          setArtisanDetail(responseData.data.data);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getTrackDetail = async (albumId) => {
    console.log("View Data clicked for album ID:", albumId); // Check if this logs on button click
    try {
      const responseData = await axios.post(API_BASE_URL + "get_album_by_id", {
        id: albumId,
      });
      console.log("API response data:", responseData); // Log the API response
      if (!responseData.data.status) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      } else {
        const data = responseData.data.data;
        setTrackDetail(data); // This should set the state with the data
        setModalIsOpen(true);
        setIsButtonVisible(false); // Hide the button when modal opens
      }
    } catch (error) {
      console.error("Error fetching track details:", error); // Log any error from the API call
    } finally {
      setIsLoading(false);
    }
  };

  const getMintsByAlbum = async (albumId) => {
    try {
      const responseData = await axios.post(API_BASE_URL + "mint-data-by-album", {
        id: albumId,
      });
      if (!responseData.data.status) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      } else {
        setMintData(responseData.data.data); // Set the mint data
        setMintDataModalOpen(true); // Open the modal after fetching data
      }
    } catch (error) {
      console.error("Error fetching mint details:", error); // Log any error from the API call
    } finally {
      setIsLoading(false);
    }
  };
  
  

  useEffect(() => {
    if (artistData) {
      getArtisianDetail();
    }
  }, [artistData]);

  useEffect(() => {
    Modal.setAppElement("#root");
    getArtistData();
  }, []);

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error.message}</Typography>;
  }

  return (
    <Box>
      {artisanDetail && artisanDetail.length ? (
        artisanDetail.map((item, index) => (
          <Box sx={styles.DropBox} key={index}>
            <Box sx={styles.DropLeftArea}>
              <Box sx={styles.DropImg}>
                <Box
                  component={"img"}
                  src={`${IMAGE_URL}${item.qr_image}`}
                  alt="Drop Image"
                  sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Box>
              <Box sx={styles.DropContent}>
                <Typography variant="h6" sx={styles.DropBoxTitle}>
                  {item?.album_name}
                </Typography>
                <Typography component={"p"} sx={styles.DropBoxSubTitle}>
                  {item?.slug}
                </Typography>
                <Box sx={styles.DropBoxInfoItem}>
                  <Typography component={"span"} sx={styles.DropBoxInfoText}>
                    Total Mints
                  </Typography>
                  <Typography component={"span"} sx={styles.DropBoxInfoValue}>
                    {item?.demu_mint}
                  </Typography>
                </Box>

                {/* Original "View Data" button */}
                <Button
                  variant="contained"
                  sx={styles.ViewDataBtn} // Keep your original styling here
                  onClick={() => getTrackDetail(item.album_id)}
                >
                  View Sales
                </Button>
                <Button
                  variant="contained"
                  sx={styles.MintDataBtn} // Keep your original styling here
                  onClick={() => getMintsByAlbum(item.album_id)} // Fetch mint data on click
                >
                  Mint Data
                </Button>
              </Box>
            </Box>

            {/* Mint Data Section with New View Data Button on top */}
            <Box sx={{ position: 'relative', height: 'auto' }}>
              {/* Mint Data Section */}
              <Box component={"ul"} sx={styles.MintsUl}>
                {mintTypes.map((mintType, mintIndex) => (
                  <Box component={"li"} sx={styles.MintsLi} key={mintIndex}>
                    <Box
                      component={"img"}
                      src={`/assets/images/${mintType.key}.png`}
                      alt={`${mintType.label} Icon`}
                      sx={styles.MintIcon}
                    />
                    <Box sx={styles.MintInfo}>
                      <Box sx={styles.MintInfoItem}>
                        <Typography component={"span"} sx={styles.MintInfoText}>
                          Mints - {" "}
                        </Typography>
                        <Typography component={"span"} sx={styles.MintInfoValue}>
                          {item[mintType.key] ? item[mintType.key] : "0"}
                        </Typography>
                      </Box>
                      <Box sx={styles.MintInfoItem}>
                        <Typography component={"span"} sx={styles.MintInfoText}>
                          Earned - {" "}
                        </Typography>
                        <Typography component={"span"} sx={styles.MintInfoValue}>
                          £{item[mintType.amountKey] ? item[mintType.amountKey] : "0"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>

              {/* New View Data Button (positioned on top of the blurred section) */}
              {isButtonVisible && ( // Only show if button should be visible
                <Button
                  onClick={() => getTrackDetail(item.album_id)} // Fetch details on click
                  sx={styles.blurViewDataButton} // Positioned absolutely in the middle of the li
                >
                  View Sales
                </Button>
              )}
            </Box>
          </Box>
        ))
      ) : (
        <Typography>No Artisan Details Available</Typography>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);
          setIsButtonVisible(true); // Show the new button again when the modal is closed
        }}
        contentLabel="Track Details"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "30px",
            width: "80%",
            maxWidth: "800px",
            maxHeight: "80vh",
            overflow: "auto",
            borderRadius: "24px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
          {/* Close Button */}
      <button 
        onClick={() => {
          setModalIsOpen(false);
          setIsButtonVisible(true); // Ensure button reappears on modal close
        }} 
        style={{
          ...styles.modalCloseButton, // existing styles if any
          width: '30px',            // Make the button round
          height: '30px',           // Make the button round
          borderRadius: '50%',      // Make the button circular
          backgroundColor: '#fff',  // Set background color (white in this case)
          border: 'none',           // Remove border
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Add subtle drop shadow
          display: 'flex',          // Flexbox to center the content
          alignItems: 'center',     // Center vertically
          justifyContent: 'center', // Center horizontally
          cursor: 'pointer',        // Pointer on hover
          fontSize: '20px',         // Set font size for the 'X'
          color: '#333',            // Set color for the 'X'
        }}
      >
        &times;
      </button>
        {trackDetail ? (
          <Box>
            <Typography variant="h6" sx={styles.ModalHeaderText}>View Mints</Typography>
            <Box component={"ul"} sx={styles.ModalMintsUl}>
              {mintTypes.map((mintType, mintIndex) => (
                <Box component={"li"} sx={styles.ModalMintsLi} key={mintIndex}>
                  <Box
                    component={"img"}
                    src={`/assets/images/${mintType.key}.png`}
                    alt={`${mintType.label} Icon`}
                    sx={styles.ModalMintIcon}
                  />
                  <Box sx={styles.MintInfo}>
                    <Box sx={styles.MintInfoItem}>
                      <Typography component={"span"} sx={styles.DropBoxInfoText}>
                        Mints - {" "}
                      </Typography>
                      <Typography component={"span"} sx={styles.DropBoxInfoValue}>
                        {trackDetail[mintType.key] || "0"}
                      </Typography>
                    </Box>
                    <Box sx={styles.MintInfoItem}>
                      <Typography component={"span"} sx={styles.DropBoxInfoText}>
                        Earned - {" "}
                      </Typography>
                      <Typography component={"span"} sx={styles.DropBoxInfoValue}>
                        £{trackDetail[mintType.amountKey] || "0"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        ) : (
          <Typography>Loading...</Typography>
        )}

      </Modal>

      <Modal
        isOpen={mintDataModalOpen}
        onRequestClose={() => setMintDataModalOpen(false)}
        contentLabel="Mint Data"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "30px",
            width: "80%",
            maxWidth: "800px",
            maxHeight: "80vh",
            overflow: "auto",
            borderRadius: "24px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <button
          onClick={() => setMintDataModalOpen(false)}
          style={{
            width: '30px',            // Make the button round
            height: '30px',           // Make the button round
            borderRadius: '50%',      // Make the button circular
            backgroundColor: '#fff',  // Set background color (white in this case)
            border: 'none',           // Remove border
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Add subtle drop shadow
            display: 'flex',          // Flexbox to center the content
            alignItems: 'center',     // Center vertically
            justifyContent: 'center', // Center horizontally
            cursor: 'pointer',        // Pointer on hover
            fontSize: '20px',         // Set font size for the 'X'
            color: '#333',            // Set color for the 'X'
            position: 'absolute',
            top: '15px',
            right: '15px',
          }}
        >
          &times;
        </button>
        <Typography
          variant="h6"
          style={{
            fontWeight: 'bold',
            fontSize: '1.25rem',
            marginBottom: '20px',
            color: '#041549',
          }}
        >
          Mint Data
        </Typography>

        <TableContainer sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
            xl: "block",
          },
          borderRadius: '8px', boxShadow: 'none'
        }}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: '#E4EBFC' }}> {/* Updated colors */}
                <TableCell style={{ color: '#041549', fontWeight: 'bold' }}>RANK</TableCell>
                <TableCell style={{ color: '#041549', fontWeight: 'bold' }}>USER</TableCell>
                <TableCell style={{ color: '#041549', fontWeight: 'bold' }}>VALUE SPENT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mintData.map((mint, index) => (
                <TableRow key={index}>
                  <TableCell style={{ borderBottom: '1px solid #ddd' }}>{mint.rank}.</TableCell>
                  <TableCell style={{ borderBottom: '1px solid #ddd' }}>{mint.address}</TableCell>
                  <TableCell style={{ borderBottom: '1px solid #ddd' }}>
                    {mint.amount} ${mint.currency}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>


        <Box
    component="div"
    style={{
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    }}
  >
    {mintData.map((mint, index) => (
      <Box
        key={index}
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none",
            xl: "none"
          },
          flexDirection: "column",
          padding: "10px",
          borderRadius: "8px",
        }}
      >
        <Box
          component="div"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "5px",
          }}
        >
          <Typography
            style={{
              color: "#003852",
            }}
          >
            RANK
          </Typography>
          <Typography sx={{
            color: '#003852'
          }}>{mint.rank}.</Typography>
        </Box>

        <Box
          component="div"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "5px",
          }}
        >
          <Typography
            style={{
              color: "#003852",
            }}
          >
            USER
          </Typography>
          <Typography sx={{
            color: '#003852'
          }}>{mint.address}</Typography>
        </Box>

        <Box
          component="div"
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #ddd",
          }}
        >
          <Typography
            style={{
              color: "#003852",
            }}
          >
            VALUE SPENT
          </Typography>
          <Typography sx={{
            color: '#003852'
          }}>
            {mint.amount} ${mint.currency}
          </Typography>
        </Box>
      </Box>
    ))}
  </Box>
      </Modal>


    </Box>
  );
}

export default AnalyticsItem;
