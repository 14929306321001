import { Avatar, Box, Container, Typography, IconButton, Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState, useRef } from "react";  // Added useRef to handle the audio element
import { selectCurrentUser } from "../../redux/reducers/userSlice";
import { useAppSelector } from "../../redux/hooks";
import Header from "../../components/Header_Homepage";
import Footer from "../../components/Footer";
import theme from "../../Theme";
import { styles } from "./style";
import axios from 'axios';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';  // MUI play icon
import PauseIcon from '@mui/icons-material/Pause'; // Optional: Add a pause button
import { API_BASE_URL, IMAGE_URL } from '../../url';

const Home = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);  // State to store API data
  const [currentIndex, setCurrentIndex] = useState(0);  // For managing slider position
  const itemsPerPage = 4;  // Number of cards to show at a time
  const audioRef = useRef(null);  // Create a reference for the audio element
  const [isPlaying, setIsPlaying] = useState(false);  // State to manage play/pause status
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);  // State to manage which track is playing

  const currentUser = useAppSelector(selectCurrentUser);

  useEffect(() => {
    // Fetch data from the API on component mount
    async function fetchData() {
      try {
        const response = await axios.get(API_BASE_URL + 'get-latest-drops');  // Replace with your API URL
        setData(response.data.data);  // Extract the 'data' array from the response
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  // Fetch sales data for the "Recent Activities" section
  const [salesData, setSalesData] = useState([]);

  useEffect(() => {
    async function fetchSalesData() {
      try {
        const response = await axios.get(API_BASE_URL + 'get-latest-activities');  // Fetch sales data
        setSalesData(response.data.data);  // Store the sales data
      } catch (error) {
        console.error("Error fetching sales data:", error);
      }
    }
    fetchSalesData();
  }, []);

  // Calculate which items to display
  const displayedItems = data.slice(currentIndex, currentIndex + itemsPerPage);

  const handleNext = () => {
    if (currentIndex + itemsPerPage < data.length) {
      setCurrentIndex(currentIndex + itemsPerPage);
    }
  };

  const handlePrev = () => {
    if (currentIndex - itemsPerPage >= 0) {
      setCurrentIndex(currentIndex - itemsPerPage);
    }
  };

  const handlePlayPause = (index, musicUrl) => {
    // Toggle play/pause functionality
    if (currentPlayingIndex === index) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    } else {
      if (audioRef.current) {
        audioRef.current.src = musicUrl;
        audioRef.current.play();
        setCurrentPlayingIndex(index);
        setIsPlaying(true);
      }
    }
  };

  return (
    <>
      <Container maxWidth="xl" sx={styles.homeContainer}>
        <Header />

        <Box sx={styles.section1Box}>
          <Typography
            className="revealator-slideup revealator-duration15 revealator-delay1"
            sx={{
              color: theme.palette.primary.logoColor,
              fontSize: {
                xs: "24px",
                sm: "24px",
                md: "40px",
                lg: "40px",
              },
              fontWeight: "700",
              lineHeight: {
                xs: "34.68px",
                sm: "34.68px",
                md: "57.8px",
                lg: "57.8px",
              },
            }}
          >
            Latest Drops
          </Typography>
          
          {/* Arrow for Prev */}
          <Box 
              onClick={handlePrev}
              sx={{
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
                width: {
                  xs: '33px',
                  sm: '33px',
                  md: '66px',
                  lg: '66px',
                },
                height: {
                  xs: '33px',
                  sm: '33px',
                  md: '66px',
                  lg: '66px',
                },
                color: theme.palette.primary.logoColor,
                cursor: 'pointer',
                position: 'absolute',
                right: {
                  xs: '70px',
                  sm: '70px',
                  md: '153px',
                  lg: '153px',
                },
                zIndex: 1,
                opacity: currentIndex === 0 ? 0.5 : 1, // Disable effect
                pointerEvents: currentIndex === 0 ? 'none' : 'auto',  // Disable interaction
              }}
            >
              <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="65" height="65" rx="32.5" stroke="currentColor"/>
                <g clip-path="url(#clip0_843_5158)">
                <path d="M37.9488 23.1L28.0488 33L37.9488 42.9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_843_5158">
                <rect width="39.6" height="39.6" fill="white" transform="translate(13.1992 13.2)"/>
                </clipPath>
                </defs>
              </svg>
          </Box>
          {/* Arrow for Next */}
          <Box 
              onClick={handleNext}
              sx={{
                display: {
                  xs: 'flex',
                  sm: 'flex',
                  md: 'flex',
                  lg: 'flex',
                },
                justifyContent: 'center',
                alignItems: 'center',
                width: {
                  xs: '33px',
                  sm: '33px',
                  md: '66px',
                  lg: '66px',
                },
                height: {
                  xs: '33px',
                  sm: '33px',
                  md: '66px',
                  lg: '66px',
                },
                color: theme.palette.primary.logoColor,
                cursor: 'pointer',
                position: 'absolute',
                right: {
                  xs: '30px',
                  sm: '30px',
                  md: '48px',
                  lg: '48px',
                },
                zIndex: 1,
                opacity: currentIndex + itemsPerPage >= data.length ? 0.5 : 1,  // Disable effect
                pointerEvents: currentIndex + itemsPerPage >= data.length ? 'none' : 'auto',  // Disable interaction
              }}
            >
              <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="-0.5" y="0.5" width="65" height="65" rx="32.5" transform="matrix(-1 0 0 1 65 0)" stroke="currentColor"/>
                <g clip-path="url(#clip0_843_5161)">
                <path d="M28.0512 23.1L37.9512 33L28.0512 42.9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_843_5161">
                <rect width="39.6" height="39.6" fill="white" transform="matrix(-1 0 0 1 52.8008 13.2)"/>
                </clipPath>
                </defs>
              </svg>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: {
              xs: 'space-around',
              lg: 'space-between',
            },
            position: 'relative',
            marginTop: {
              xs: '18.24px',
              sm: '18.24px',
              md: '30px',
              lg: '30px',
            },
            marginLeft: {
              xs: '0',
              sm: '0',
              md: '0',
              lg: '0',
            },
          }}
        >
        {/* Card Section */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: {
              xs: 'space-around', // Center content for mobile
              lg: 'space-around', // Keep space between cards on larger screens
            },
            flexDirection: {
              xs: 'column', // Stack cards vertically on mobile
              lg: 'row', // Display cards in a row on larger screens
            },
            alignItems: 'center', // Center cards horizontally in mobile view
            width: '100%', // Use full width in both views
          }}
        >
          {displayedItems.map((item, index) => (
            <Box
              key={index}
              sx={{
                width: {
                  xs: '100%', // Take full width on mobile
                  lg: '251px', // Fixed width on larger screens
                },
                height: '468px',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                margin: {
                },
                position: 'relative',  // Ensure the play button can be positioned absolutely
              }}
            >
              {/* Display Cover Image */}
              <Avatar
                  src={IMAGE_URL + item.cover_image_url}
                  sx={{
                    width: {
                      xs: '100%', // Take full width on mobile
                      sm: '100%', // Take full width on mobile
                      md: '251px',
                      lg: '251px', // Fixed width on larger screens

                    },
                    height: {
                      xs: '251px', // Fixed height on mobile
                      sm: '251px', // Fixed height on mobile
                      md: '251px',
                      lg: '251px', // Fixed height on larger screens
                    },
                    borderRadius: '15px',
                    marginBottom: '16px'
                  }}
              />

              {/* Play button overlay if has_music is true */}
              {item.has_music && (
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: '28%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',  // Correct centering
                    backgroundColor: 'rgba(124, 141, 181, 0.75)',  // Semi-transparent background for contrast
                    color: '#fff',  // White color for the play icon
                    borderRadius: '50%',  // Make it circular
                    width: '53px',  // Adjust size of the play button
                    height: '53px',  // Adjust size of the play button
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 10,  // Ensure it appears on top of the image
                    '&:hover': {
                      backgroundColor: 'rgba(124, 141, 181, 0.85)',  // Full opacity on hover
                    },
                  }}
                  onClick={() => handlePlayPause(index, IMAGE_URL + item.music_url)}
                >
                  {isPlaying && currentPlayingIndex === index ? <PauseIcon /> : <PlayArrowIcon />}
                </IconButton>

              )}

              {/* Title */}
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.primary.logoColor,
                  fontWeight: '600',
                  textAlign: 'left',
                  marginBottom: '11px'
                }}
              >
                {item.title}
              </Typography>

              {/* Days Ago */}
              <Typography variant="body2" sx={{
                color: theme.palette.primary.LightText3,
                textAlign: 'left',
                marginBottom: '11px'
              }}>
                {item.days_ago}d
              </Typography>

              {/* Buy Now */}
              <Typography variant="body2" sx={{
                color: theme.palette.primary.logoColor,
                textAlign: 'left',
                paddingLeft: '4px',
                marginBottom: '10px',
              }}>
                Buy with
              </Typography>
              <Button
                sx={{
                  color: theme.palette.primary.logoColor,
                  borderColor: theme.palette.primary.logoColor,
                  backgroundColor: "transparent",
                  border: '2px solid',
                  borderRadius: '12px',
                  padding: '8px 16px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: {
                    xs: 'center',  // Center the text
                    sm: 'center',  // Center the text
                    md: 'left',  // Align text to the left
                    lg: 'left',  // Align text to the left
                  },  // Center the text
                  alignItems: {
                    xs: 'center',  // Center the text
                    sm: 'center',  // Center the text
                    md: 'center',  // Center the text
                    lg: 'center',  // Center the text
                  },
                  position: 'relative',  // Relative positioning for icons
                }}
                onClick={() => navigate(item.slug)}
              >
                {/* Buy Now Text */}
                <Typography
                  sx={{
                    fontWeight: '700',
                    color: theme.palette.primary.logoColor,
                  }}
                >
                  Buy Now
                </Typography>

                {/* Icons Stack */}
                <Box
                  sx={{
                    display: 'flex',
                    position: 'absolute',  // Position icons absolutely
                    right: '16px',  // Position on the right side of the button
                  }}
                >
                  {/* First Icon */}
                  <Avatar
                    src={ "assets/images/solana.svg" }
                    sx={{
                      width: '24px',
                      height: '24px',
                      marginLeft: '0px',
                    }}
                  />
                  {/* Second Icon */}
                  <Avatar
                    src={ "assets/images/matic.svg" }
                    sx={{
                      width: '24px',
                      height: '24px',
                      marginLeft: '-8px',  // Overlap by adjusting margin
                    }}
                  />
                  {/* Third Icon */}
                  <Avatar
                    src={ "assets/images/xrp.svg" }
                    sx={{
                      width: '24px',
                      height: '24px',
                      marginLeft: '-8px',  // Overlap by adjusting margin
                    }}
                  />
                  
                  <Avatar
                    src={ "assets/images/ada.svg" }
                    sx={{
                      width: '24px',
                      height: '24px',
                      marginLeft: '-8px',  // Overlap by adjusting margin
                    }}
                  />

                                    
                  <Avatar
                    src={ "assets/images/ethereum.svg" }
                    sx={{
                      width: '24px',
                      height: '24px',
                      marginLeft: '-8px',  // Overlap by adjusting margin
                    }}
                  />
                </Box>
              </Button>

              {/* Music Icon (only if has_music is true) */}
              {item.has_music && (
                <audio ref={audioRef} />
              )}
            </Box>
          ))}
        </Box>
        </Box>

        {/* Second section */}
        <Box
          sx={{
            ...styles.section1Box,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            marginTop: {
              xs: '120px',
              sm: '120px',
              md: '172px',
              lg: '172px',
            },
          }}
        >
        <Typography
            className="revealator-slideup revealator-duration15 revealator-delay1"
            sx={{
              color: theme.palette.primary.logoColor,
              fontSize: {
                xs: "24px",
                sm: "24px",
                md: "40px",
                lg: "40px",
              },
              fontWeight: "700",
              lineHeight: {
                xs: "34.68px",
                sm: "34.68px",
                md: "57.8px",
                lg: "57.8px",
              },
            }}
          >
            Recent Activities
        </Typography>
        
        {/* New Section for Sales Data */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            justifyContent: 'center',
            marginTop: '30px',
          }}
        >
          {/* First Column (3 items) */}
          <Box
            sx={{
              width: {
                xs: '100%',
                md: '50%',
              },
              paddingRight: {
                xs: '0',
                md: '24px',  // Add padding to the right of the first column on desktop
              },
              marginBottom: {
                xs: '20px',  // Margin at the bottom for mobile
                md: '0',     // No margin for desktop
              },
            }}
          >
            {salesData.slice(0, 3).map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  marginBottom: '32px',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    position: 'relative',  // To position the play button absolutely
                    width: "128px",
                    height: "121px",
                    borderRadius: '15px',
                    marginRight: '16px',
                  }}
                >
                  <Avatar
                    src={IMAGE_URL + item.cover_image_url}
                    sx={{
                      width: "128px",
                      height: "120.86px",
                      borderRadius: '15px',
                    }}
                  />

                  {/* Play button overlay if has_music is true */}
                  {item.has_music && (
                    <IconButton
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',  // Correct centering
                        backgroundColor: 'rgba(124, 141, 181, 0.75)',  // Semi-transparent background for contrast
                        color: '#fff',  // White color for the play icon
                        borderRadius: '50%',  // Make it circular
                        width: '40px',  // Adjust size of the play button
                        height: '40px',  // Adjust size of the play button
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 10,  // Ensure it appears on top of the image
                        '&:hover': {
                          backgroundColor: 'rgba(124, 141, 181, 0.85)',  // Full opacity on hover
                        },
                      }}
                      onClick={() => handlePlayPause(index, IMAGE_URL + item.music_url)}
                    >
                      {isPlaying && currentPlayingIndex === index ? <PauseIcon /> : <PlayArrowIcon />}
                    </IconButton>
                  )}
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography sx={{ fontWeight: '600', fontSize: '20px', lineHeight: "28.9px", color: theme.palette.primary.logoColor }}>
                    {item.title}
                  </Typography>
                  <Typography sx={{ color: theme.palette.primary.LightText3, fontSize: '18px', lineHeight: "26.01px", fontWeight: "400" }}>
                    {item.smart_contract.length > 25 
                      ? `${item.smart_contract.substring(0, 10)}...${item.smart_contract.substring(item.smart_contract.length - 10)}` 
                      : item.smart_contract}
                  </Typography>

                  <Typography sx={{ color: theme.palette.primary.LightText3, fontSize: '18px', lineHeight: "26.01px", fontWeight: "400" }}>
                    ${item.amount_usd.toLocaleString()} USD
                  </Typography>
                  
                  <Box sx={{ fontSize: '14px', color: theme.palette.primary.logoColor, display: 'flex', alignItems: 'center' }}>
                    {/* "Bought" text in light green */}
                    <Typography component="span" sx={{ color: '#28D972', fontWeight: '600', fontSize: '18px', lineHeight: "26.01px", marginRight: '4px' }}>  {/* Light green color */}
                      Sold
                    </Typography>

                    {/* SVG Circle */}
                    <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="3.5" cy="4" r="3.5" fill="#C0C0C0"/>
                    </svg>

                    {/* Pill style platform name */}
                    <Box component="span" sx={{
                      display: 'flex',  // Flex to align icon and text
                      alignItems: 'center',
                      color: theme.palette.primary.logoColor,
                      fontWeight: '600',
                      padding: '2px 8px',
                      borderRadius: '16px',
                      backgroundColor: "rgba(0, 56, 82, 0.05)",
                      marginLeft: '8px'
                    }}>
                      {/* Dynamically rendered SVG icon inside the pill */}
                      <Avatar
                        src={`assets/images/${item.platform}.svg`}  // Use the platform name to load the correct icon
                        sx={{
                          width: '16px',  // Small size for the icon
                          height: '16px',
                          marginRight: '6px'
                        }}
                      />

                      {/* Platform name */}
                      {item.good_name}
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ marginLeft: 'auto', textAlign: 'right', paddingRight: '12px' }}>
                  <Typography sx={{ color: theme.palette.primary.LightText3 }}>
                    {item.days_ago}d
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>

          {/* Divider */}
          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'block',
                lg: 'block'
              },
              width: '3px',  // Thin vertical line
              backgroundColor: "rgba(117, 132, 148, 0.15)",
              marginX: '16px',  // Add space around the divider
            }}
          />

          {/* Second Column (3 items) */}
          <Box
              sx={{
                display: {
                  xs: 'none',
                  sm: 'none',
                  md: 'block',
                  lg: 'block'
                },
              width: {
                xs: '100%',
                md: '50%',
              },
              paddingLeft: {
                xs: '0',
                md: '24px',  // Add padding to the left of the second column on desktop
              },
              paddingRight: '24px',  // Add padding to the right side of the second column to match the first
            }}
          >
            {salesData.slice(3, 6).map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  marginBottom: '20px',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    position: 'relative',  // To position the play button absolutely
                    width: "128px",
                    height: "121px",
                    borderRadius: '15px',
                    marginRight: '16px',
                  }}
                >
                  <Avatar
                    src={IMAGE_URL + item.cover_image_url}
                    sx={{
                      width: "128px",
                      height: "120.86px",
                      borderRadius: '15px',
                    }}
                  />

                  {/* Play button overlay if has_music is true */}
                  {item.has_music && (
                    <IconButton
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',  // Correct centering
                        backgroundColor: 'rgba(124, 141, 181, 0.75)',  // Semi-transparent background for contrast
                        color: '#fff',  // White color for the play icon
                        borderRadius: '50%',  // Make it circular
                        width: '40px',  // Adjust size of the play button
                        height: '40px',  // Adjust size of the play button
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 10,  // Ensure it appears on top of the image
                        '&:hover': {
                          backgroundColor: 'rgba(124, 141, 181, 0.85)',  // Full opacity on hover
                        },
                      }}
                      onClick={() => handlePlayPause(index, IMAGE_URL + item.music_url)}
                    >
                      {isPlaying && currentPlayingIndex === index ? <PauseIcon /> : <PlayArrowIcon />}
                    </IconButton>
                  )}
                </Box>

                <Box sx={{ flex: 1 }}>
                  <Typography sx={{ fontWeight: '600', fontSize: '20px', lineHeight: "28.9px", color: theme.palette.primary.logoColor }}>
                    {item.title}
                  </Typography>
                  <Typography sx={{ color: theme.palette.primary.LightText3, fontSize: '18px', lineHeight: "26.01px", fontWeight: "400" }}>
                    {item.smart_contract.length > 25 
                      ? `${item.smart_contract.substring(0, 10)}...${item.smart_contract.substring(item.smart_contract.length - 10)}` 
                      : item.smart_contract}
                  </Typography>


                  <Typography sx={{ color: theme.palette.primary.LightText3, fontSize: '18px', lineHeight: "26.01px", fontWeight: "400" }}>
                    ${item.amount_usd.toLocaleString()} USD
                  </Typography>
                  
                  <Box sx={{ fontSize: '14px', color: theme.palette.primary.logoColor, display: 'flex', alignItems: 'center' }}>
                    {/* "Bought" text in light green */}
                    <Typography component="span" sx={{ color: '#28D972', fontWeight: '600', fontSize: '18px', marginRight: '4px' }}>  {/* Light green color */}
                      Sold
                    </Typography>

                    {/* SVG Circle */}
                    <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="3.5" cy="4" r="3.5" fill="#C0C0C0"/>
                    </svg>

                    {/* Pill style platform name */}
                    <Box component="span" sx={{
                      display: 'flex',  // Flex to align icon and text
                      alignItems: 'center',
                      color: theme.palette.primary.logoColor,
                      fontWeight: '600',
                      padding: '2px 8px',
                      borderRadius: '16px',
                      backgroundColor: "rgba(0, 56, 82, 0.05)",
                      marginLeft: '8px'
                    }}>
                      {/* Dynamically rendered SVG icon inside the pill */}
                      <Avatar
                        src={`assets/images/${item.platform}.svg`}  // Use the platform name to load the correct icon
                        sx={{
                          width: '16px',  // Small size for the icon
                          height: '16px',
                          marginRight: '6px'
                        }}
                      />

                      {/* Platform name */}
                      {item.good_name}
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ marginLeft: 'auto', textAlign: 'center', paddingLeft: '16px' }}>  
                  <Typography sx={{ color: theme.palette.primary.LightText3 }}>
                    {item.days_ago}d
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>



        </Box>
        
      </Container>
      <Footer />

    </>
  );
};

export default Home;
