import React, { useEffect, useState } from "react"
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom"
import Landing from "./pages/Landing/Landing"
import Home from "./pages/Home/Home"
import Onboarding from "./pages/Onboarding/Onboarding"
import { Provider } from 'react-redux';
import store from './redux/store'
import ArtistInformation from './pages/ArtistInformation/ArtistInformation';
import Package from './pages/Package/Package';
import ProMetadata from './pages/ProMetadata/ProMetadata';
import Mint from './pages/Mint/Mint';
import ArtistDashboard from "./pages/ArtistDashboard/ArtistDashboard"
import FansDashboard from "./pages/FansDashboard/FansDashboard"
import FanLogin from "./pages/Sso/FanLogin"
import About from './pages/About/About'; // About component (newly created)
import CheckOutForm from "./pages/CheckOutForm/CheckOutForm"
import PaymentConfirmation from "./pages/PaymentConfirmation/PaymentConfirmation"
import { getClientSecret } from "./utils/common"
// import { loadStripe } from "@stripe/stripe-js"
// import { Elements } from "@stripe/react-stripe-js"
import PaymentForm from "./components/PaymentForm"
import CancelPayment from "./pages/CancelPayment/CancelPayment"
import CheckOut from "./pages/CheckOut/CheckOutPage"
// import { initNufiDappSdk } from "@nufi/dapp-client-core"
// import { initNufiDappCardanoSdk } from "@nufi/dapp-client-cardano"

// warm up Nufi SSO widget
// initNufiDappSdk()
// initNufiDappCardanoSdk("sso")



function App() {
  // const [stripePromise, setStripePromise] = useState(loadStripe('pk_test_CIptLw3IEqegLCqMu1FGcMHC'));
  // const [clientSecret, setClientSecret] = useState("");
  // const [amount,setAmount]=useState()
  // // const stripePromise = loadStripe('pk_test_CIptLw3IEqegLCqMu1FGcMHC');

  // useEffect(()=>{
  //   getClientSecret().then((r)=>{
  //     console.log("app.js client secret ",r)
  //     setClientSecret(r.client_secret)
  //     setAmount(r.amount)
  //   })
  // },[])




  return (<>
    {/* {clientSecret && stripePromise && ( */}
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:slug" element={<Landing />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/artistInformation" element={<ArtistInformation />} />
          <Route path="/package" element={<Package />} />
          <Route path="/proMetadata" element={<ProMetadata />} />
          <Route path="/mint" element={<Mint />} />
          <Route path="/fanLogin" element={<FanLogin />} />
          <Route path="/artistDashboard" element={<ArtistDashboard />} />
          <Route path="/paymentConfirmation" element={<PaymentConfirmation />} />
          <Route path="/checkOut" element={<CheckOut />} />
          <Route path="/checkOutFormStripe" element={<PaymentForm />} />
          <Route path="/cancelPayment" element={<CancelPayment />} />
          <Route path="/about" element={<About />} />
          {/* <ProtectedRouteComponent path="/artistDashboard" element={<ArtistDashboard />} /> */}

          <Route path="/fansDashboard" element={<FansDashboard />} />
        </Routes>
      </Router>
    </Provider>
    {/* */}
  </>


  )
}

export default App
