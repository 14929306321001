import React, { useEffect } from "react"
import { Avatar, Box, Container, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material"
import theme from "../Theme"
import { Icon } from '@iconify/react';
import { IMAGE_URL } from "../url";
import { styles } from "./styles/fansHeaderStyles"
import { clearCurrentUser, setCurrentUser } from "../redux/reducers/userSlice";
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import nufiCoreSdk from "@nufi/dapp-client-core"
const settings = ['Profile', 'Dashboard', 'Logout'];

const FansHeader = ({ artist, getArtistData }) => {
  // console.log("header ", artist)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [blockchainNetwork, __] = React.useState(process.env.REACT_APP_BLOCKCHAIN_NETWORK)
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const getAlbumImage = (album_image) => {
    if (album_image) {
      // console.log("albumImage", album_image);
      let coverImage = album_image.split('/');
      // console.log("coverImage ==> ", coverImage);
      // coverImage.splice(1, 0, "storage");
      coverImage.shift()
      const coverImageStr = coverImage.join('/');
      // console.log("coverImageStr ==> ", IMAGE_URL + coverImageStr);
      return IMAGE_URL + coverImageStr
    }

    return null
  }
  const handleLogout = () => {
    if (blockchainNetwork === "preprod") {
      nufiCoreSdk.init("https://wallet-testnet-staging.nu.fi") // Initiate NuFi Wallet
    } else {
      //TODO add NUFI mainnet url once available
    }
    localStorage.removeItem("currentUser")
    localStorage.removeItem(`nufi-social-login-${blockchainNetwork}`)
    dispatch(clearCurrentUser())
    nufiCoreSdk.getApi().signOut() // Signout from NuFi Wallet
    // getArtistData()
    navigate("/")
  }
  return (
    <Container maxWidth="xl">
      <Box
        sx={styles.fansHeaderContainer}
      >
        <Box
          sx={styles.logoContainer}
          href="#"
          component={"a"}
          className="revealator-slideright revealator-duration15 revealator-delay5"
        >
          <Box sx={styles.logo}
            component={"img"}
            src={"/assets/images/logo.png"}
            alt="Logo"
          />
        </Box>

        <Box>
          <Box
            sx={styles.socialContainer}
            component={"a"}
            href="#"
            className="revealator-slideleft revealator-duration15 revealator-delay5"
          >
            <Box
              component={"img"}
              src={"/assets/images/SocialButtonWhite.png"}
              alt="Google"
              sx={{
                width: {
                  xs: "36px",
                  sm: "36px",
                },

              }}
            />
          </Box>

        </Box>
        <Box
          sx={styles.headerRightPartContainer}
        >
          <Box
            sx={styles.headerRightPart}
            className="revealator-slideleft revealator-duration15 revealator-delay5"
          >
            <Typography
              sx={styles.tokenBalanceText}
            >
              $Stage Token Balance :
            </Typography>

            <Box
              sx={styles.comingSoonPart}
              component={"a"}
              href="#"
            >
              <Typography
                sx={styles.comingSoonText}
              >
                Coming Soon
              </Typography>
              <Box sx={styles.eyeIconContainer}>
                <Icon icon="heroicons:eye" />
              </Box>
            </Box>
          </Box>
          <Box sx={styles.profileButtonContainer}
            className="revealator-slideleft revealator-duration10 revealator-delay10"
          >
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={styles.profileButton}>
                <Avatar sx={styles.avatar} alt="Remy Sharp" src={artist?.picture ? getAlbumImage(artist.picture) : ("/assets/images/artist-avatar.png")} />
                <Box sx={styles.dropDownIcon}><Icon icon="fa6-solid:angle-down" /></Box>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                setting === "Logout" ?
                  (<MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center" onClick={handleLogout} >{setting}</Typography>
                  </MenuItem>
                  ) : (<MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                  )

                // <MenuItem key={setting} onClick={handleCloseUserMenu}>
                //   <Typography textAlign="center">{setting}</Typography>
                // </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>
      </Box>
    </Container >

  )
}

export default FansHeader
